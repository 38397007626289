<template>
  <b-modal
    id="modalDriverBankData"
    v-model="cpHandlerModal"
    lazy
    no-fade
    no-enforce-focus
    hide-footer
    size="xl"
    title="Dados Bancários"
    :cancel-title="$t('payments.buttons.close')"
    @show="handleOpen"
    @hidden="handleClose"
  >
  <b-container>
    <div class="row" style="min-height: 200px;">
      <div class="col-8" style="border-right: 1px solid #f2f2f2">
        <b-form validate>

          <b-form-row class="lines">
            <b-form-group
              label="Selecione um Banco"
              label-class="font-weight-bold"
              label-for="transaction-description"
              class="col-md-12 col-sm-12"
              :state="!$v.form.banco.$error"
            >
              <input-select-search
                :options="options"
                v-on:selected="selectedBank"
                :disabled="false"
                name="zipcode"
                placeholder="Selecione um Banco"
              />
              
              <b-form-invalid-feedback
                v-if="$v.form.banco.$error"
                :force-show="$v.form.banco.$error"
                v-html="$t('payments.labels.mandatoryField')"
              />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group
                label="Tipo de Conta"
                label-class="font-weight-bold"
                label-for="transaction-description"
                class="col-md-4 pt-1"
              >
                <b-form-select
                  v-model="form.tipoConta"
                  :options="optionsBankAccoountType"
                  :state="!$v.form.tipoConta.$error ? null : false"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>
                      Selecione um Tipo
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                v-if="$v.form.tipoConta.$error"
                :force-show="$v.form.tipoConta.$error"
                v-html="$t('payments.labels.mandatoryField')" />
            </b-form-group>

            <b-form-group
              label="Agencia"
              label-class="font-weight-bold"
              class="col-md-3"
              :state="!$v.form.agencia.$error"
            >
              <b-input
                v-model="form.agencia"
                type="number"
              />

              <b-form-invalid-feedback
                v-if="!$v.form.agencia.required"
                :force-show="!$v.form.agencia.required"
                class="pl-3 pt-0 mt-0"
                v-html="$t('payments.labels.mandatoryField')"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.agencia.minLength"
                :force-show="!$v.form.agencia.minLength"
                class="pl-3 pt-0 mt-0"
                v-html="`Tamanho mínimo ${$v.form.agencia.$params.minLength.min} números`"
              />
            </b-form-group>

            <b-form-group
              label="Digito Agência"
              label-class="font-weight-bold"
              class="col-md-2"
              :state="!$v.form.digitoAgencia.$error"
              :disabled="!this.bank.branch_digit_required"
            >
              <b-input
                v-model="form.digitoAgencia"
                type="number"
                maxlength="1"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.digitoAgencia.required"
                :force-show="!$v.form.digitoAgencia.required"
                class="pl-3 pt-0 mt-0"
                v-html="$t('payments.labels.mandatoryField')"
              />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group
              label="Conta"
              label-class="font-weight-bold"
              class="col-md-3"
              :state="!$v.form.conta.$error"
            >
              <b-input
                v-model="form.conta"
                type="number"
                aria-describedby="password-help-block"
                :maxlength="$v.form.conta.$params.maxLength.max"
              />

              <b-form-invalid-feedback
                v-if="!$v.form.conta.required"
                :force-show="!$v.form.conta.required"
                class="pl-3 pt-0 mt-0"
                v-html="$t('payments.labels.mandatoryField')"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.conta.minLength"
                :force-show="!$v.form.conta.minLength"
                class="pl-3 pt-0 mt-0"
                v-html="`Tamanho mínimo ${$v.form.conta.$params.minLength.min} números`"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.conta.maxLength"
                :force-show="!$v.form.conta.maxLength"
                class="pl-3 pt-0 mt-0"
                v-html="`Tamanho máximo ${$v.form.conta.$params.maxLength.max} números`"
              />
            </b-form-group>

            <b-form-group
              label="Digito"
              label-class="font-weight-bold"
              class="col-md-2"
              :state="!$v.form.digito.$error"
              :disabled="!this.bank.digit_length"
            >
              <b-input
                v-model="form.digito"
                type="number"
                :maxlength="$v.form.digito.$params.maxLength.max"
              />

              <b-form-invalid-feedback
                v-if="!$v.form.digito.required"
                :force-show="!$v.form.digito.required"
                class="pl-3 pt-0 mt-0"
                v-html="$t('payments.labels.mandatoryField')"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.digito.minLength"
                :force-show="!$v.form.digito.minLength"
                class="pl-3 pt-0 mt-0"
                v-html="`Tamanho mínimo ${$v.form.digito.$params.minLength.min} números`"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.digito.maxLength"
                :force-show="!$v.form.digito.maxLength"
                class="pl-3 pt-0 mt-0"
                v-html="`Tamanho máximo ${$v.form.digito.$params.maxLength.max} números`"
              />
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group
              label="CPF"
              label-class="font-weight-bold"
              label-for="transaction-description"
              class="col-md-4"
            >
              <the-mask
                id="payment-holder-cpf"
                v-model.trim="form.cpf"
                type="text"
                readonly
                class="form-control"
                mask="###.###.###-##"
              />
              <b-form-invalid-feedback
                v-if="$v.form.cpf.$error"
                :force-show="$v.form.cpf.$error"
                class="pl-3 pt-0 mt-0"
                v-html="$t('payments.labels.mandatoryField')"
              />
            </b-form-group>
            <div class="col-12">
              <b-alert show variant="warning">
                <strong>Se o Dígito da Agência for obrigatório e no cartão do cliente for uma letra(por exemplo X),
                é necessário preencher o campo com 0.</strong>
              </b-alert>
            </div>
          </b-form-row>
        </b-form>
      </div>
      <b-form-row class="lines">
        
      </b-form-row>
      <div class="col-4">
        <list-bank-account
          v-if="render"
          :driverId="profile.id"
          @updateDefault="setDefaultBankAcount"
          @delete="deleteBankAccount"
        >
        </list-bank-account>
      </div>
    </div>

    <div class="row py-4">
      <div class="col-2 offset-4">
        <b-button
          data-test="modal-transaction__btn-close"
          class="relative"
          block
          @click="handleClose"
        >
          Cancelar
        </b-button>
      </div>

      <div class="col-2">
        <b-button
          variant="success"
          data-test="modal-transaction__btn-save"
          block
          @click="handleSubmit"
        >
          Cadastrar
        </b-button>
      </div>
    </div>
  </b-container>
  </b-modal>
</template>

<script>
import CREATE_BANK_ACOUNT from '@graphql/payment/mutations/create-bank-acount.gql';
import SET_DEFAULT_BANK_ACOUNT from '@graphql/payment/mutations/set-default-bank-acount.gql';
import DELETE_BANK_ACCOUNT from '@graphql/payment/mutations/delete-bank-acount.gql';
import GET_BANKS from '@graphql/payment/queries/getBanks.gql';
import { BANK_ACCOUNT_TYPE_LIST } from '@enums/bankAccountType';
import { makeMutation, makeQuery } from "@graphql/middleware";
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
export default {
  name: 'ModalDriverBankData',
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    handlerModal: {
      type: Boolean,
    },
  },
  components: {
    InputSelectSearch: () => import('@components/shared/input-select-search'),
    ListBankAccount: () => import('@components/drivers/bank-account/list-bank-account')
  },
  mixins: [validationMixin],
  validations() {
    return {
      form: {
        agencia: {
          required,
          minLength: minLength(this.bank?.branch_length || 0)
        },
        conta: {
          required,
          minLength: minLength(this.bank?.min_account_length || 0),
          maxLength: maxLength(this.bank?.max_account_length || 0),
        },
        digito: {
          required:  !!this.bank?.digit_length ? required : false,
          minLength: minLength(this.bank?.digit_length || 0),
          maxLength: maxLength(this.bank?.digit_length || 0),
        },
        digitoAgencia: {
          required:  !!this.bank?.branch_digit_required ? required : false
        },
        cpf: {
          required
        },
        banco: {
          required
        },
        tipoConta: {
          required
        }
      }
    }
  },
  data () {
    return {
      render: true,
      bank: {},
      form: {
        agencia: null,
        digitoAgencia: null,
        conta: null,
        digito: null,
        cpf: null,
        banco: null,
        tipoConta: null,
      },
      options: [],
      optionsBankAccoountType: BANK_ACCOUNT_TYPE_LIST
    }
  },
  computed:{
    ...mapGetters('driver', {
      profile: 'header'
    }),
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    }
  },
  methods: {
    selectedBank (item) {
      this.form.banco = item.id;
      this.bank = item
    },
    getBanks () {
      makeQuery(
        GET_BANKS
      )
      .then((res) => {
        this.options = res.data.getBanks.map((bank) => {
          return {
            ...bank,
            id: bank.code,
            name: `${bank.code} - ${bank.name}`
          }
        })
      })
    },
    createBankAcount () {
      let agencia = this.form.agencia
      let conta = this.form.conta
      if (!!this.bank.branch_digit_required) {
        agencia = `${this.form.agencia}-${this.form.digitoAgencia}`
      }
      if (!!this.bank?.digit_length) {
        conta = `${this.form.conta}-${this.form.digito}`
      }
      makeMutation(
        CREATE_BANK_ACOUNT,
          {
            account_type: this.form.tipoConta,
            bank_code: this.form.banco,
            branch: agencia,
            number: conta,
            driver: this.profile.id
          }
        )
        .then(() => {
          this.showSuccessModal()
        })
        .catch(() => {
          this.showErrorModal()
        })
    },
    setDefaultBankAcount (accountId) {
      makeMutation(
        SET_DEFAULT_BANK_ACOUNT,
          {
            accountBankId: accountId,
          }
        )
        .then(() => {
          this.showSuccessModal()
          this.$emit('refresh')
        })
        .catch(() => {
          this.showErrorModal()
        })
    },
    deleteBankAccount (accountId) {
      makeMutation(
        DELETE_BANK_ACCOUNT,
          {
            accountBankId: accountId,
          }
        )
        .then(() => {
          this.showSuccessModal(false)
          this.reloadList()
        })
        .catch(() => {
          this.showErrorModal()
        })
    },
    handleClose() {
      this.resetForm()
      this.$emit('change', false);
    },
    handleOpen() {
      this.$v.$reset();
      this.form.cpf = this.profile.cpf;
      this.getBanks()
    },
    handleSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.createBankAcount()
      }
    },
    reloadList() {
      this.render = false;
      this.$nextTick(() => {
        this.render = true;
      });
    },
    resetForm () {
      this.form = {
        agencia: null,
        digitoAgencia: null,
        conta: null,
        digito: null,
        cpf: null,
        banco: null,
        tipoConta: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    showSuccessModal(close = true) {
      this.$swal({
        type: 'success',
        title: 'Ação realizada com Sucesso!',
        showConfirmButton: false,
        timer: 2500,
      }).then(() => this.$emit('refresh'))
      if (close) {
        this.handleClose();
      }
    },
    showErrorModal() {
      this.$swal({
        type: 'error',
        title: 'Ocorreu um erro!',
        text: 'Verifique as informações novamente',
        showConfirmButton: false,
        timer: 2500,
      }).then(() => (this.block_ui = false));
    },
  }
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
</style>
